import { IconMoon } from '@tabler/icons';
import { IconSun } from '@tabler/icons';
import cx from 'classnames';
import { useColorMode } from '../../useColorMode';
import styles from './ColorModeSwitch.module.scss';

type Props = { size?: number };

export function ColorModeSwitch({ size = 1.25 }: Props) {
  const [mode, setMode] = useColorMode();
  return (
    <label
      className={styles.ColorModeSwitch}
      style={{ fontSize: `${size}rem` }}
    >
      <input
        type="checkbox"
        checked={mode === 'dark'}
        title={`Switch to ${mode === 'light' ? 'dark' : 'light'} mode`}
        onChange={() => setMode(mode === 'light' ? 'dark' : 'light')}
      />
      <div className={styles.Switch}>
        <IconMoon className={cx([styles.ColorModeIcon, styles.Moon])} />
        <IconSun className={cx([styles.ColorModeIcon, styles.Sun])} />
      </div>
    </label>
  );
}

export default ColorModeSwitch;
