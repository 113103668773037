import Cookies from 'js-cookie';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export type Mode = 'dark' | 'light';

function setModeClass(mode: Mode) {
  console.log(mode);
  document.documentElement.classList.remove('light');
  document.documentElement.classList.remove('dark');
  document.documentElement.classList.add(mode);
}

function getColorMode() {
  if (
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 'dark';
  }
  return 'light';
}

function registerListener(
  setMode: (mode: ((mode: Mode) => Mode) | Mode) => void,
) {
  if (typeof window !== 'undefined' && window.matchMedia) {
    const listener = (event: MediaQueryListEventMap['change']) => {
      setMode(event.matches ? 'dark' : 'light');
    };
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', listener);
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', listener);
    };
  }
}

function setCookie(mode: string) {
  if (typeof window !== 'undefined') {
    Cookies.set('color-mode', mode, { expires: 7 });
  }
}

function getFromCookie(): Mode | undefined {
  if (typeof window !== 'undefined') {
    const value = Cookies.get('color-mode');
    if (value === 'dark' || value === 'light') {
      return value;
    }
  }
}

const ModeContext = createContext<[Mode, Dispatch<SetStateAction<Mode>>]>([
  'light',
  () => {},
]);

export function ModeProvider(props: { mode?: Mode; children: ReactNode }) {
  console.log(props.mode);
  const defaultMode = getFromCookie() || props.mode || getColorMode();
  const modeState = useState<Mode>(defaultMode);
  const [mode, setMode] = modeState;

  useEffect(() => registerListener((mode) => setMode(mode)), [setMode]);

  useEffect(() => {
    setModeClass(mode);
    setCookie(mode);
  }, [mode]);

  return (
    <ModeContext.Provider value={modeState}>
      {props.children}
    </ModeContext.Provider>
  );
}

export function useColorMode(): [
  Mode,
  (mode: ((mode: Mode) => Mode) | Mode) => void,
] {
  return useContext(ModeContext);
}
