import type { AppProps } from 'next/app';
import { Layout } from '../components/Layout/Layout';
import '../globals.scss';
import { ModeProvider } from '../useColorMode';

function MyApp({ Component, pageProps }: AppProps): React.ReactElement {
  return (
    <ModeProvider mode={pageProps.colorMode}>
      <Layout landing={pageProps.landing} wide={pageProps.wide}>
        <Component {...pageProps} />
      </Layout>
    </ModeProvider>
  );
}

export default MyApp;
